module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Navigo System BH', // Navigation and Site Title
    titleAlt: 'Navigo System BH', // Title for JSONLD
    description: '',
    headline: '', // Headline for schema.org JSONLD
    url: '', // Domain of your site. No trailing slash!
    logo: '', // Used for SEO
    ogLanguage: 'en_US', // Facebook Language
  
    // JSONLD / Manifest
    favicon: '', // Used for manifest favicon generation
    shortName: '', // shortname for manifest. MUST be shorter than 12 characters
    author: '', // Author for schemaORGJSONLD
    themeColor: '',
    backgroundColor: '',
  
    twitter: '', // Twitter Username
    facebook: '', // Facebook Site Name
    googleAnalyticsID: ''
  }