// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-product-index-js": () => import("/opt/build/repo/src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-subcategory-index-js": () => import("/opt/build/repo/src/templates/subcategory/index.js" /* webpackChunkName: "component---src-templates-subcategory-index-js" */),
  "component---src-templates-map-index-js": () => import("/opt/build/repo/src/templates/map/index.js" /* webpackChunkName: "component---src-templates-map-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stores-js": () => import("/opt/build/repo/src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */)
}

