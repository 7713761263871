/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Header from "./header"
import Menu from "./menu"
import Footer from "./footer"
import "./layout.css"
import "./garmin-spec.min.css"
import "./base.lib-v1.9-min.css"
import "./carousel.css"
import "./product-page-v2.2.0.css"
import { loadReCaptcha } from "react-recaptcha-google"
import favicon from "../images/favicon-navigo.png";

import "jquery"
import "bootstrap/dist/css/bootstrap.css"

/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */
import { useStaticQuery, graphql, Link } from "gatsby"

import i18n from "../../config/i18n"

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(query)
  var categories = []
  
  if (data.categories !== "undefined") {
    categories = data.categories.edges.filter(edge => edge.node.lang === locale)
  }
  const showEnLanguage = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.show_en_language_option)
    .toString()

  const navigoLogo = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.navigo_logo.url)
    .toString()

  const navigoLogoWhite = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.navigo_logo_white.url)
    .toString()

  const pricelists = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.pricelists)

  const itemsToDownload = data.allPrismicItemsToDownload.edges.filter(
    edge => edge.node.lang === locale
  )

  const maps = data.maps.edges.filter(edge => edge.node.lang === locale)
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          showENLangOption={showEnLanguage}
          navigoLogoImg={navigoLogo}
          categories={categories}
          itemsToDownload={itemsToDownload}
          maps={maps}
        />
        <Menu
          categories={categories}
          itemsToDownload={itemsToDownload}
          maps={maps}
        />
        <main>{children}</main>
        <Footer pricelists={pricelists} navigoLogoImg={navigoLogoWhite} />
      </>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            show_en_language_option
            pricelists {
              pricelist_title
              pricelist_link {
                url
              }
            }
            navigo_logo {
              url
            }
            navigo_logo_white {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    categories: allPrismicCategory {
      edges {
        node {
          lang
          data {
            category_name
            subcategories {
              subcategory {
                document {
                  uid
                  data {
                    subcategory_name
                  }
                }
              }
            }
          }
        }
      }
    }
    maps: allPrismicMapCategory {
      edges {
        node {
          lang
          uid
          data {
            map_category_name {
              text
            }
          }
        }
      }
    }
    allPrismicItemsToDownload {
      edges {
        node {
          lang
          data {
            item_name {
              text
            }
            download_link {
              url
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
