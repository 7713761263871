import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { LocaleContext } from "../components/layout"
import MobileMenu from "./mobileMenu"

const locales = require("../../config/i18n")

const Header = ({
  siteTitle,
  showENLangOption,
  navigoLogoImg,
  categories,
  itemsToDownload,
  maps,
}) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }
  var localizedHomepagePath = ""

  if (locales[lang.locale] != undefined) {
    localizedHomepagePath = locales[lang.locale].default
      ? ""
      : `${locales[lang.locale].path}`
  }
  return (
    <header>
      <div className="header-container">
        <div className="container">
          <div>
            {typeof window !== "undefined" && MobileMenu && (
              <MobileMenu
                categories={categories}
                itemsToDownload={itemsToDownload}
                maps={maps}
              />
            )}
          </div>

          <div className="header-logo">
            <Link to={localizedHomepagePath}>
              <img className="img-responsive" src={navigoLogoImg} alt="Logo" />
            </Link>
          </div>
          {showENLangOption === "true" && (
            <div data-name="locale-switcher" className="locale-switcher">
              <Link hrefLang="bs-ba" to="/" style={{ color: `black` }}>
                BA
              </Link>
              /
              <Link hrefLang="en-us" to="/en" style={{ color: `black` }}>
                EN
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  showENLangOption: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  showENLangOption: ``,
}

export default Header
