const website = require('./website')

module.exports = {
  "en-us": {
    default: false,
    path: "en",
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    special_offer: 'Special Offer',
    products: 'Products',
    about_us: 'About Us',
    maps:'Maps',
    featured_products: 'Featured Products',
    details: 'Details',
    inquire: 'Inquire',
    related_products: 'Related Products',
    description: 'Description',
    specification: 'Specification',
    in_the_box: 'In The Box',
    email_label: 'Your Email',
    submit_message: '* Please submit your request and we will contact you shortly',
    submit_label: 'SUBMIT',
    infomation: 'MORE LINKS',
    store_info: 'STORE INFORMATION',
    contact_us: 'Contact Us',
    pricing: 'Pricing',
    other_stores:'Stores',
    garmin_express: 'Download Garmin Express',
    discover: 'Discover',
    other_poduct_models: 'Other Product Models',
    thank_you_msg: 'Thank You! Your request has been successfully sent. We will contact you shortly',
    close: 'Close',
    other_stores_title: 'Stores',
    pricelists_title: 'Pricelists',
    visit_us: 'Visit Us On:',
    email: 'E-mail: '
  },
  "bs-ba": {
    default: true,
    path: "ba",
    locale: 'bs-ba',
    siteLanguage: 'ba',
    ogLang: 'bs_BA',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: '',
    headline: '',
    special_offer: 'Posebna ponuda',
    products: 'Proizvodi',
    about_us: 'O nama',
    maps:'Kartografija',
    featured_products: 'Istaknuti proizvodi',
    details: 'Detaljnije',
    inquire: 'Upit',
    related_products: 'Možda će Vam se isto svidjeti',
    description: 'Opis proizvoda',
    specification: 'Karakteristike',
    in_the_box: 'Sadržaj paketa',
    email_label: 'Vaša e-mail adresa',
    submit_message: '* Molimo Vas da pošaljete svoj upit i mi ćemo Vam se javiti čim prije',
    submit_label: 'Pošalji',
    infomation: 'INFO',
    store_info: 'O PRODAVNICI',
    contact_us: 'Kontaktirajte nas',
    pricing: 'Cjenovnik',
    other_stores: 'Prodajna mjesta',
    garmin_express: 'Preuzmite Garmin Express aplikaciju',
    discover:'Pronađite',
    other_poduct_models: 'Drugi modeli',
    thank_you_msg: 'Hvala Vam! Vaš zahtjev je poslan uspješno i mi ćemo Vas kontaktirati ubrzo.',
    close: 'Zatvori',
    other_stores_title: 'Prodajna mjesta',
    pricelists_title: 'Cjenici',
    visit_us: "Posjetite nas na:",
    email: 'E-mail: '
  },
}
