import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { LocaleContext } from "../components/layout"

import i18next from "i18next"

const locales = require("../../config/i18n")

const Footer = ({ pricelists, navigoLogoImg }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  const localizedAboutUsPath = (locales[lang.locale] != undefined
  ? locales[lang.locale].default
  : "about-us")
    ? "about-us"
    : `${locales[lang.locale].path}${"/about-us"}`

  const localizedOtherStoresPath = (locales[lang.locale] != undefined
  ? locales[lang.locale].default
  : "stores")
    ? "stores"
    : `${locales[lang.locale].path}${"/stores"}`
  return (
    <footer>
      <div id="footer" className="container">
        <div className="row three-column-footer">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div className="footer-box">
              <div id="footerlogo">
                <div className="footerdiv">
                  <div
                    className="footerlogo d-none d-sm-block"
                    style={{ maxWidth: "200px" }}
                  >
                    <img
                      className="img-responsive"
                      src={navigoLogoImg}
                      alt="Logo"
                    />
                  </div>
                  <div className="social-block">
                    <div className='visit-us'>{i18n.visit_us}</div>
                    <div className="facebook">
                      <a className='fb-icon' href="https://www.facebook.com/Garmin-BiH-Navigo-sistem-BH-557218181053081/">
                        
                      </a>
                    </div>
                    <div className="olx">
                      <a className='olx-icon' href="https://www.olx.ba/profil/GARMIN_BH/">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div className="footer-box">
              <p className="title">{i18n.infomation}</p>
              <a href={localizedAboutUsPath}>{i18n.about_us}</a>
              <br />
              <a href={localizedOtherStoresPath}>{i18n.other_stores}</a>
              <br />
              {pricelists.length > 0 &&
                pricelists.map(
                  items =>
                    items.length > 0 &&
                    items.map(item => (
                      <a href={item.pricelist_link.url} target="_blank">
                        {item.pricelist_title}
                        <br />
                      </a>
                    ))
                )}
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4  ">
            <div className="footer-box">
              <p className="title">{i18n.store_info}</p>
              <div className="text">
                <div className="block">
                  <i class="material-icons inline store-icon">location_on</i>
                  <p className="inline-block m-l-20 store-info">
                    Navigo Sistem d.o.o. &nbsp;
                    <br />
                    Dalmatinska 4
                    <br />
                    71000 Sarajevo
                  </p>
                </div>
                <div className="block">
                  <i class="material-icons inline store-icon">call</i>
                  <p className="inline-block m-l-20 store-info">
                    Tel. +387 33 226 223
                    <br />
                    Fax +387 33 226 223
                  </p>
                </div>
                <div className="block">
                  <i class="material-icons inline store-icon">
                    alternate_email
                  </i>
                  <p className="inline-block m-l-20 store-info">
                    <a className="contact-email" href="mailto:garminbh@icloud.com">
                      garminbh@icloud.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="bottom-footer" className="bottomfooter">
        <div className="container">
          <div id="powered" className="powered">
            Powered By Navigo Sistem d.o.o. © 2019
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  pricingPdf: PropTypes.string,
}

Footer.defaultProps = {
  pricingPdf: ``,
}

export default Footer
