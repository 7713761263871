import React from "react"
import { LocaleContext } from "./layout"

import "react-push-menu/styles/component.css"
import PushMenu from "react-push-menu"
import { FaBars, FaChevronRight, FaChevronLeft } from "react-icons/fa"
import Link from "./linkComponent"

const locales = require("../../config/i18n")

const MobileMenu = ({ categories, itemsToDownload, maps }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  var menu = {
    header: '',
    children: []
  }
  
  //add categories to menu
  for (var i = 0; i < categories.length; i++) {
    var child = {
      name: "",
      children: [],
    }
    for (var j = 0; j < categories[i].node.data.subcategories.length; j++) {
      var item = categories[i].node.data.subcategories[j].subcategory
      if (item != null) {
        for (var k = 0; k < item.document.length; k++) {
          var subcategory = item.document[k]
          var childOfChild = {
            name: "",
            link: "",
            children: [],
          }
          childOfChild.name = subcategory.data.subcategory_name
          childOfChild.link = "/" + subcategory.uid
          child.children.push(childOfChild)          
        }
      }
      child.name = categories[i].node.data.category_name
    }
    menu.children.push(child)
    menu.header = i18n.products
  }

  //add maps to menu
  for (var i = 0; i < maps.length; i++) {
    var child = {
      name: "",
      children: [],
    }
     child.name = maps[i].node.data.map_category_name.text;
     child.link = '/' + maps[i].node.uid;

    menu.children.push(child);
  }

  //add maps to menu
  for (var i = 0; i < itemsToDownload.length; i++) {
    var child = {
      name: "",
      children: [],
    }
     child.name = itemsToDownload[i].node.data.item_name.text;
     child.link = itemsToDownload[i].node.data.download_link.url;

    menu.children.push(child);
  }

  return (
    // Desktop navbar
    <div className="d-sm-none mobile-menu">
      <PushMenu
        backIcon={<FaChevronLeft />}
        expanderComponent={FaChevronRight}
        linkComponent={Link}
        isOpen={false}
        nodes={menu}
        type={"cover"}
        propMap={{ url: "link" }}
      >
        <div className="rpm-trigger" id="rpm-trigger">
          <FaBars />
        </div>
      </PushMenu>
    </div>
  )
}

export default MobileMenu
