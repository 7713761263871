import React from "react"
import { LocaleContext } from "../components/layout"
import {
  MenuList,
  MenuItem,
  MenuButton,
  Dropdown,
  SubMenuItem,
} from "react-menu-list"

import 'react-push-menu/styles/component.css';
import PushMenu from 'react-push-menu';

const locales = require("../../config/i18n")

const Menu = ({ categories, itemsToDownload, maps }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if(lang.i18n[lang.locale] != undefined){

    i18n = lang.i18n[lang.locale];
   }

  return (
    // Desktop navbar
    <nav className="nav-container d-none d-md-block">
      <div className="nav-inner container">
        <MenuButton
          className="menu-option"
          menu={
            <Dropdown>
              <MenuList>
                {categories && categories.map(category => (
                  <SubMenuItem
                    style={{ userSelect: "none" }}
                    highlightedStyle={{ background: "black", color: "white" }}
                    className="submenu-item"
                    menu={
                      <Dropdown>
                        <MenuList>
                          {category.node.data.subcategories.map(
                            item =>
                              item.subcategory &&
                              item.subcategory.document.map(subcategory => (
                                <a
                                  href={subcategory.uid}
                                  className="submenu-item-wrap"
                                >
                                  <SubMenuItem
                                    style={{ userSelect: "none" }}
                                    highlightedStyle={{
                                      background: "black",
                                      color: "white",
                                    }}
                                    className="submenu-item"
                                  >
                                    {subcategory.data.subcategory_name}
                                  </SubMenuItem>
                                </a>
                              ))
                          )}
                        </MenuList>
                      </Dropdown>
                    }
                  >
                    {category.node.data.category_name}
                  </SubMenuItem>
                ))}
              </MenuList>
            </Dropdown>
          }
        >
          {i18n.products}
        </MenuButton>

        <MenuButton
          className="menu-option"
          menu={
            <Dropdown>
              <MenuList>
                {maps.map(map => (
                  <div className="subitem-menu">
                    <a href={map.node.uid} className="submenu-item-wrap">
                      <SubMenuItem
                        style={{ userSelect: "none" }}
                        highlightedStyle={{
                          background: "black",
                          color: "white",
                        }}
                        className="submenu-item"
                      >
                        {map.node.data.map_category_name.text}
                      </SubMenuItem>
                    </a>
                  </div>
                ))}
              </MenuList>
            </Dropdown>
          }
        >
          {i18n.maps}
        </MenuButton>
        <MenuButton
          className="menu-option"
          menu={
            <Dropdown>
              <MenuList>
                {itemsToDownload.map(item => (
                  <div className="subitem-menu">
                    <a
                      href={item.node.data.download_link.url}
                      className="submenu-item-wrap"
                    >
                      <SubMenuItem
                        style={{ userSelect: "none" }}
                        highlightedStyle={{
                          background: "black",
                          color: "white",
                        }}
                        className="submenu-item"
                      >
                        {item.node.data.item_name.text}
                      </SubMenuItem>
                    </a>
                  </div>
                ))}
              </MenuList>
            </Dropdown>
          }
        >
          {i18n.discover}
        </MenuButton>
      </div>
    </nav>
  )
}

export default Menu
